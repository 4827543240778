import React from 'react';
import { Navbar, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './NavMenu.css';
import useNavBar from '../hooks/useNavBar';

export default function ReportNavMenu() {
    
    const { loading } = useNavBar();
    const path = window.location.pathname.split('/')[1];

    return (
        !loading ?
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink to={path + "/form"} className="nav-link">Filters</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={path + "/options"} className="nav-link">View Options</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={path + "/preview"} className="nav-link">Preview</NavLink>
                        </NavItem>
                    </ul>
                    <div className="nav-link">Your data is refreshed in the background every hour.</div>
            </Navbar>
        </header>
        : null
    );
}
